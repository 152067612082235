/* ==== "Inter" FONT-FAMILY FROM FONTS.GOOGLE.COM  ==== */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
/* ==== ROOT RESET ==== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  
}

/* ==== CSS VARIABLES ==== */
:root {
  /*background-color: #161c2d;*/
  --primary-color: #335eea;
  --link-color: #506690;
  --btn-hover-color: #2b50c7;
  --lg-heading: #161c2d;
  --text-content: #869ab8;
  --fixed-header-height: 4.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}