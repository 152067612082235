
/* ==== CSS VARIABLES ==== */
:root {
  --primary-color: #335eea;
  --link-color: #506690;
  --btn-hover-color: #2b50c7;
  --lg-heading: #161c2d;
  --text-content: #869ab8;
  --fixed-header-height: 4.5rem;
}


ul li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
/* ==== CONTAINER ==== */
.container {
  width: 100%;
}
@media screen and (min-width: 1040px) {
  .container {
    width: 1040px;
    margin: 0 auto;
  }
}

/* ====  WRAPPER ==== */
.wrapper {
  width: 100%;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-top: 5rem;
  margin-bottom: 5rem;
}

.grid-cols-3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  padding: 1rem;
}
.grid-col-item {
  height: 100%;
}
.about-title{
  font-size: 20px;
}
.icon {
  width: 100%;
  line-height: 40px;
}
.icon svg {
  width: 30px;
  height: 30px;
  /*color: #6b85d8;*/
  color: white;
}
.featured_info {
  width: 100%;
}
.featured_info span {
  width: 100%;
  display: block;
  font-size: 21px;
  line-height: 33px;
  color: var(--primary-color);
  
}
.featured_info p {
  display: block;
  width: 100%;
  margin-top: 7px;
  font-weight: 400;
  color: #334157;
  line-height: 25px;
  font-size: 15.5px;
  color: white;
}
footer {
  width: 100%;
  background-color: var(--primary-color);
  height: 12px;
  margin-top: 8rem;
}


/*Cookie Bar*/
.cookie-banner {
  position: fixed;
  bottom: 0px;
  left: 10%;
  right: 10%;
  width: 80%;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.cookie-banner  > p{
  font-size: 13px;
  font-weight: 400;
  color: #333;
}
@media only screen and (max-width: 700px){
  .cookie-banner  > p{
    font-size: 7.5px;
    font-weight: 200;
    color: #333;
  }
  
}
.close {
  height: 20px;
  position: relative;
  font-size: 20px;
  top: -5px;
  border: none;
  color: black;
  border-radius: 2px;
  cursor: pointer;
}