
/* Popup style */

.popup-box {
  position: absolute;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  width: 180px;
  margin: 0 auto;
  z-index: 99;
  float:right; 
  right: 10%;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: absolute;
  right: calc(3px);
  top: calc(5px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .box {
    right: 5%;
    margin-top: 20px;
  }

}
